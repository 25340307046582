
.intro-text {
  padding: 0 0 2rem 0;
  line-height: 1.5;
}

.band-members {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.band-member {
  flex: 1 0 50%;
}

.band-member img {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .band-members {
    flex-direction: column;
  }
  .band-member {
    flex: 1 0 100%;
  }
}

.member-names-h2{ 
padding: 0.5rem 0 2rem 0;
font-style: italic;
}

