

header{ 
width: 100%;
display: flex;
flex-wrap: wrap;
padding: 3rem 10%;;
background-color: #fafafa;

}
h1{ 

font-weight: 600;
font-size: 2.25rem;
letter-spacing: 0.10rem;

}

h2{
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
    color:#746f69;
    font-size: 1rem;
}

.img-container{ 
  height:80px;
  padding-right:2rem;
}


@media screen and (min-width:1100px) {
  header{ padding: 5rem 20%;}
}