*{ margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    font-size: 1rem;
    font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;

  }
  