.layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  background-color: #fafafa;
}

main{
  display: block;
  padding: 5rem 10%;
}

.main-container-left {
  margin-right: 17rem;
  
}
.main-container-right{
 width:50%;
}

.titles-page {
  font-family: "Libre Franklin", sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
}
@media screen and (max-width:1320px) {
  main {
   flex-wrap: wrap;
  }}

  
@media screen and (min-width:1100px) {
  main{ padding: 5rem 20%;}
  .main-container-left {
    margin-right: 14rem;
    
  }
}