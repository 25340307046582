.navigation-container-ul {
  width: 100%;
  display: block;
  display: flex;
  align-items: center;

}
.container-navbar{
  width: 100%;
  font-size: 1rem;
  padding: 2.2rem 10%;
  border: 1px solid #f3f3f3;
  background-color: #ffffff;

}
.icon-container{
  line-height: 1.3;
width: 100%;
height: 100%;




}

.menu-icon-svg{
  width: 25px;
  height: 25px;
}
.burger-button{ 

display: inline-block;
vertical-align: middle;
background: transparent;
 border: none;
}
.menu-text{
  display: inline-block;
  margin-left:0.5rem;
 vertical-align: middle;
  

}
.li-items {
  padding-right: 2rem;
  list-style: none;

}

.li-items a {
  font-size: 1rem;
  text-decoration: none;
  color: black;
}

@media screen and (max-width:800px) {
  .navigation-container-ul{
   flex-direction: column;
   align-items: normal;
   margin-top: 1rem;
   width: 100%;
  }

  .li-items {
    padding-top: 1rem;
    border-bottom: #f0f0f0 solid 1px;
    width: 100%;


  }
  .container-navbar{ 
    padding: 1rem 0 0 auto;
  }

}


@media screen and (min-width:1100px) {

  .container-navbar{
     padding: 5rem 20%;
    
    }
    
}




